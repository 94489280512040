import React from "react"
import Slideshow from "../components/bb/slideshow"
import Table from "../components/bb/table"
import { css } from "@emotion/react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/bb/button"
// import { Link } from "gatsby"

let amenities = [
  ["Towels, soap, and toilet paper","Hangers","Bed linens"],
  ["Indoor fireplace", "Air conditioning", "Heating"],
  ["External security cameras on property", "Fire extinguisher", "Smoke/Carbon monoxide alarm"],
  ["Dishes", "Silverware", "Kitchen"],
  ["Pots and pans", "oil", "salt and pepper"],
  ["Private entrance", "Garden/Yard", "Patio/balcony"],
  ["Free parking on site", "Long term stays allowed", "Building Staff"],
];
// append green checkmark infront of each
amenities = amenities.map((el) =>{
  return el.map((e)=>{
    return (
    <div>
      <span css={css`
        color: var(--secondary);
        font-weight: bolder;
        text-shadow: rgba(var(--secondaryalt), 0.75) 1px 2px 4px;
      `}>✔ </span>
      {e}
    </div>)
  })
});

export default function CabinThree() {

  const bookButton = (
    <Button
      value="Book on Airbnb"
      href="https://www.airbnb.com/rooms/46395404?#availability-calendar"
      customstyle='padding: 24px 36px; margin: 24px 0;'
    />
  );

  return(
    <Layout>
    <SEO title="The Serenity Cabin" />
      <h1>Serenity cabin situated in ambiance!</h1>
            
      <Slideshow imagefilter='cabin3' />
      
      {bookButton}
      
      <div id="section1">
        <h2>Property Description</h2>

        <p>Tucked back at the base of a heavily wooded ridge, this comfy cabin has home sweet home written all over it. 
          Located on Crooked Creek, a renowned trout stream, allows fisherman easy access. The 1st floor has an open 
          floor plan with the sitting area joined with kitchen area. The upper level has your sleeping areas and views 
          to keep you coming back. This cabin is perfect for your weekend getaway or extended stays to enjoy the area. 
          Check out our other cabins and hunting packages also available. This is a comfortable cabin with natural wood tones 
          throughout. The fireplace is gas which also provide heat for the cabin as well.
        </p>
        
        <h3>Sleeping arrangements</h3>
        <p>6 guests · 2 bedrooms · 3 beds · 1 bath</p>

        <h3>Guest access</h3>
        <p>Upon arrival, our Serenity cabin is located in REAR RIGHT of cabin grounds which consists of 4 cabins total.</p>
        <h3>Other things to note</h3>
        <p>Guests to bring personal towels and soaps. Sheets and bedding provided and cleaned before and after stay.
           Check out is 11 am to allow time to clean for next guests arriving.
        </p>
      </div>
      <div id="amenities">
        <h2>amenities</h2>
        <Table data={amenities} />
      </div>

      {bookButton}
      
  </Layout>   
  )
}
